import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { ChangeOrderReference } from '../../../enums/change-order-reference';
import { ChangeOrderStatus } from '../../../enums/change-order-status';
import { ModuleProperties } from '../modules/module-properties';
import { TermSummary } from '../../estimate-services/term-summary';
import { BulkUpdateInfo } from '../../bulk-update-info';
import { DomainEstimateTotalsCalculator } from '../../calculators/domain/domain-estimate-totals-calculator';
import { EstimateId } from '../estimate';
import { CompoundEscalationPercentOptions } from '../../compound-escalation-options';
import { ModulePropertiesList } from '../module-properties-list';
import { AmountPercent } from '../amount-percent';
import { BasePricing } from '../../pricing/base-pricing';
import { EstimateLocksState } from '../estimate-locks-state';

import { ChangeOrderCustomValue } from './change-order-custom-value';

/** Base change order information. */
export class BaseChangeOrder {

  /** ID. */
  public readonly id: number;

  /** Final estimate ID. */
  public readonly finalEstimateId: number;

  /** Name. */
  public readonly name: string;

  /** Description. */
  public readonly description: string;

  /** Status. */
  public readonly status: ChangeOrderStatus;

  /** Reference (can be empty). */
  public readonly reference: ChangeOrderReference | null;

  /** Client change order number (can be empty). */
  public readonly clientCONumber: string;

  /** Mech CO number (can be empty). */
  public readonly mechCONumber: string;

  /** Mech COP number (can be empty). */
  public readonly mechCOPNumber: string;

  /** Custom values. */
  public readonly customValues: readonly ChangeOrderCustomValue[];

  /** Gross margin. */
  public readonly grossMargin: AmountPercent;

  /** Sell price. */
  public readonly sellPrice: number;

  /** Created date. */
  public readonly createdAt: Date | null;

  /** Updated date. */
  public readonly updatedAt: Date | null;

  /** Bulk update info. */
  public readonly bulkUpdateInfo: BulkUpdateInfo;

  /** Locks state info. */
  public readonly locksStateInfo: EstimateLocksState;

  public constructor(data: BasicChangeOrderInitArgs) {
    this.id = data.id;
    this.finalEstimateId = data.finalEstimateId;
    this.name = data.name;
    this.description = data.description;
    this.status = data.status;
    this.reference = data.reference;
    this.clientCONumber = data.clientCONumber;
    this.mechCONumber = data.mechCONumber;
    this.mechCOPNumber = data.mechCOPNumber;
    this.customValues = data.customValues;
    this.grossMargin = data.grossMargin;
    this.sellPrice = data.sellPrice;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.bulkUpdateInfo = data.bulkUpdateInfo;
    this.locksStateInfo = data.locksStateInfo;
  }
}

type BasicChangeOrderInitArgs = BaseChangeOrder;

/** Preview change order information. */
export class PreviewChangeOrder extends BaseChangeOrder {

  /** Order. */
  public readonly order: number;

  public constructor(data: PreviewChangeOrderInitArgs) {
    super(data);
    this.order = data.order;
  }
}

type PreviewChangeOrderInitArgs = PreviewChangeOrder;

/** Detailed change order information. */
export class DetailedChangeOrder extends BaseChangeOrder {

  /** Module properties. */
  public readonly modulesProperties: ModulePropertiesList;

  /** Totals by subcontractors, expenses, custom, labor, material. */
  public readonly modulesTotals: ModuleProperties;

  /** Service term totals. */
  public readonly terms: readonly TermSummary[];

  /** Compound escalation options. */
  public readonly compoundEscalation: CompoundEscalationPercentOptions;

  /** Totals by modules and services. */
  public readonly totals: BasePricing;

  public constructor(data: DetailedChangeOrderInitArgs) {
    super(data);
    this.modulesProperties = data.modulesProperties;
    this.modulesTotals = data.modulesTotals;
    this.terms = data.terms;
    this.compoundEscalation = data.compoundEscalation;
    this.totals = DomainEstimateTotalsCalculator.calculateTotals(this.modulesTotals, this.terms);
  }
}

type DetailedChangeOrderInitArgs = StrictOmit<DetailedChangeOrder, 'totals'>;

/** Change order create model. */
export type CreateChangeOrder = Pick<BaseChangeOrder, 'name' | 'description'> & {

  /** Estimate ID for creating Change order. Null if estimate isn't merged in change order. */
  readonly estimateId: EstimateId | null;
};

/** Change order edit model. */
export type EditChangeOrder = Pick<BaseChangeOrder,
  | 'name'
  | 'description'
  | 'status'
  | 'reference'
  | 'clientCONumber'
  | 'mechCONumber'
  | 'mechCOPNumber'
  | 'customValues'
>;
